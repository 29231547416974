import React, { useContext } from 'react';
import { GlobalDataContext } from "../components/GlobalDataProvider";
import EntityDetail from "../components/EntityDetail";
import Sidebar from "../components/Sidebar";
import {useParams} from "react-router-dom";


const CompanyDetail = () => {
  const {companiesDetails} = useContext(GlobalDataContext);
  const {id} = useParams();

  return (
    <Sidebar>
        <EntityDetail
          entity={companiesDetails ? companiesDetails[id] : null}
          entityName="Firmy"
          apiPath="/company"
          navPath="/companies"
          dateFrom={companiesDetails ? companiesDetails[id].data.report.companyDetail.timeInterval.from : null}
          dateTo={companiesDetails ? companiesDetails[id].data.report.companyDetail.timeInterval.to : null}
        />
    </Sidebar>
  );
};

export default CompanyDetail;