import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {Box, Portal} from "@mui/material";
import {GridToolbarQuickFilter} from "@mui/x-data-grid";
import {DataGridPro} from "@mui/x-data-grid-pro";

const EntityList = ({ entityName, listData, columns, basePath }) => {
  const navigate = useNavigate();

  const handleEntitySelect = (id, name) => {
    navigate(`${basePath}/${id}`, { state: { name } });
  };

  const datatableStyling = {
    width: "100%",
    height: "82vh",
    display: "flex",
    justifySelf: "center",
    alignSelf: "center",
    boxShadow: 0,
    borderColor: 'white',
    marginBottom: 3,
    backgroundColor: 'white',
    marginTop: 1,
  };

  function MyCustomToolbar() {
    return (
      <React.Fragment>
        <Portal container={() => document.getElementById('filter-panel')}>
          <GridToolbarQuickFilter size="small" variant="outlined"
                                  sx={{ margin: 2, backgroundColor: 'white', height: 34, borderRadius: "4px" }} />
        </Portal>
      </React.Fragment>
    );
  }

  return (
    <Box>
      <Typography variant="h5" fontWeight={600}>{entityName}</Typography>
      <Typography variant="h7" fontWeight={400}>
        <Link to="/home" style={{ textDecoration: 'none', color: 'black' }}>NOHO</Link> -> {entityName}
      </Typography>
      <Box sx={{ width: "100%", marginTop: "10px", backgroundColor: '#f5f5f5' }}>
        <Box id="filter-panel" />
      </Box>
      <DataGridPro
        rows={listData || []}
        slots={{ toolbar: MyCustomToolbar }}
        columns={columns(handleEntitySelect)}
        sx={datatableStyling}
        searchBar={true}
        hideFooter
      />
    </Box>
  );
};

export default EntityList;
