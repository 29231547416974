import {React, useEffect, useState} from 'react';
import {DataGridPro} from "@mui/x-data-grid-pro";
import {contractsColumns} from "../data/DatatableColumns";
import Typography from "@mui/material/Typography";
import {Paper} from "@mui/material";
import {csCZ} from "@mui/x-data-grid/locales";

function Contracts({data}) {
  const [rows, setRows] = useState([]);


  useEffect(() => {
    if (data) generateRows()
  }, [data]);


  const generateRows = () => {
    let rows = [];
    data.report.contractList.forEach((contract) => {

      let newCostAssumption = 0;
      let newScope = 0;
      let realized = 0;

      contract.userValueList.forEach((userValue) => {
        userValue.businessMonthAssumptionList.forEach((assumption) => {
          newCostAssumption += assumption.value || 0;
        })

        userValue.businessMonthScopeList.forEach((scope) => {
          newScope += scope.value || 0;
        })
      })

      data.report.receivedInvoiceList.forEach((invoice) => {
        if (invoice.contract === contract.code) {
          realized += invoice.actualPayment;
        }
      })
      rows.push({
        id: contract.code,
        code: contract.code,
        name: contract.description,
        contractNumber: contract.contractNumber,
        startDate: contract.startDate,
        endDate: contract.endDate,
        costAssumption: contract.costAssumption,
        scope: contract.scope,
        newCostAssumption: newCostAssumption,
        newScope: newScope,
        realized: realized,
        remaining:newScope - realized,
      })
      setRows(rows);
      console.log("rows", rows);
    })
  }

  return (
    <Paper>
      <Typography
        sx={{
          paddingLeft: 2,
          paddingTop: 2,
          paddingBottom: 2,
          fontWeight: 'bold',
          backgroundColor: 'white',
          position: 'sticky',
          left: 0,
          fontSize: "0.85rem",
        }}
      >
        Zakázky
      </Typography>
      <DataGridPro
        columns={contractsColumns()}
        rows={rows}
        hideFooter
        localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
        sx={{
          height: "78vh",
        '&.MuiDataGrid-root': {
          borderRadius: '0px',
          borderLeft: 'none',
          borderRight: 'none',
        },
      }}/>
    </Paper>
  );
}

export default Contracts;