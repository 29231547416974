import React, { useContext } from 'react';
import { centresColumns } from "../data/DatatableColumns";
import { GlobalDataContext } from "../components/GlobalDataProvider";
import EntityList from "../components/EntityList";
import Sidebar from "../components/Sidebar";


const Centres = () => {
  const { listOfCentres } = useContext(GlobalDataContext);

  return (
    <Sidebar>
    <EntityList
      entityName="Střediska"
      listData={listOfCentres}
      columns={centresColumns}
      basePath="/centre"
    />
    </Sidebar>
  );
};

export default Centres;
