import React, {useContext, useEffect, useState} from 'react';
import Sidebar from "../components/Sidebar";
import {Box} from '@mui/material';
import Typography from "@mui/material/Typography";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {csCZ as datePickersCsCZ} from "@mui/x-date-pickers/locales";
import {csCZ as dataGridCsCZ} from "@mui/x-data-grid/locales";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from "dayjs";
import {Link} from "react-router-dom";
import {GlobalDataContext} from "../components/GlobalDataProvider";
import {DataGridPro, gridClasses} from "@mui/x-data-grid-pro";
import {groupsAndActivitiesColumns, groupsAndActivitiesGroupingModel} from "../data/DatatableColumns";
import {groupsRows} from "../data/DatatableRows";

export default function Groups() {
  const {groupsAndActivitiesCentres} = useContext(GlobalDataContext);
  const [businessMonthFrom, setBusinessMonthFrom] = useState("0124");
  const [businessMonthTo, setBusinessMonthTo] = useState("1224");
  const [months, setMonths] = useState([]);
  const getTreeDataPath = (row) => row.hierarchy;


  useEffect(() => {
    if (groupsAndActivitiesCentres && groupsAndActivitiesCentres.length > 0) {
      generateMonthArray(businessMonthFrom, businessMonthTo)
    }
  }, [businessMonthFrom, businessMonthTo, groupsAndActivitiesCentres]);

  const groupingColDef = {
    headerName: 'Skupina',
    minWidth: 200,
    filterable: true,
    disableColumnMenu: false,
    hideDescendantCount: true,
  };


  function convertDateToMMYYDayjs(date) {
    return dayjs(date).format('MMYY').toString();
  }

  const handleDateFromChange = (newValue) => {
    setBusinessMonthFrom(convertDateToMMYYDayjs(newValue.toString()));
  };
  const handleDateToChange = (newValue) => {
    setBusinessMonthTo(convertDateToMMYYDayjs(newValue.toString()));
  };

  function convertStringToDate(dateString) {
    if (!dateString || dateString === '') {
      return;
    }
    if (dateString.length !== 4) {
      return;
    }
    const month = parseInt(dateString.substring(0, 2), 10);
    const year = parseInt(`20${dateString.substring(2, 4)}`, 10);

    return dayjs(new Date(year, month - 1, 1));
  }

  function generateMonthArray(from, to) {
    if (!from || !to) {
      return [];
    }
    const fromMonth = parseInt(from.substring(0, 2), 10);
    const fromYear = parseInt(`20${from.substring(2, 4)}`, 10);
    const toMonth = parseInt(to.substring(0, 2), 10);
    const toYear = parseInt(`20${to.substring(2, 4)}`, 10);

    let startDate = dayjs(new Date(fromYear, fromMonth - 1, 1));
    const endDate = dayjs(new Date(toYear, toMonth - 1, 1));

    const months = [];

    while (startDate.isBefore(endDate) || startDate.isSame(endDate, 'month')) {
      const month = startDate.format('MMYY');
      months.push(month);
      startDate = startDate.add(1, 'month');
    }

    setMonths(months);
  }

  return (
    <Sidebar>
      <Typography variant="h5" fontWeight={600}>Data podle skupin</Typography>
      <Typography variant="h7" fontWeight={400}><Link to="/home"
                                                      style={{textDecoration: 'none', color: 'black'}}>NOHO</Link> ->
        Skupina </Typography>
      <Box sx={{width: "100%", marginTop: "10px", backgroundColor: '#f5f5f5', padding: 2, marginBottom: 2}}>
        <LocalizationProvider adapterLocale="cs" dateAdapter={AdapterDayjs}
                              localeText={datePickersCsCZ.components.MuiLocalizationProvider.defaultProps.localeText}>
          <DatePicker
            label="Od"
            views={['year', 'month']}
            value={convertStringToDate(businessMonthFrom)}
            onChange={handleDateFromChange}
            sx={{marginRight: "15px", backgroundColor: "white", borderRadius: "4px"}}
          />
          <DatePicker
            label="Do"
            views={['year', 'month']}
            value={convertStringToDate(businessMonthTo)}
            onChange={handleDateToChange}
            sx={{marginRight: "15px", backgroundColor: "white", borderRadius: "4px"}}
          />
        </LocalizationProvider>
      </Box>
      {
        groupsAndActivitiesCentres && groupsAndActivitiesCentres.length > 0 && months.length > 0 ? (
            <DataGridPro
              treeData
              rows={groupsRows(groupsAndActivitiesCentres, months)}
              columns={groupsAndActivitiesColumns(months)}
              disableChildrenFiltering
              initialState={{
                pinnedColumns: {
                  left: ["__tree_data_group__"],
                  right: ['sum_assumptions', 'sum_reality', 'total_assumption_values', 'total_values']
                },
              }}
              getTreeDataPath={getTreeDataPath}
              hideFooter
              disableColumnReorder
              localeText={dataGridCsCZ.components.MuiDataGrid.defaultProps.localeText}
              groupingColDef={groupingColDef}
              columnGroupingModel={groupsAndActivitiesGroupingModel(months)}
              getCellClassName={(params) => {
                if (params.colDef.headerName.includes("Skutečnost") && (params.rowNode.groupingKey.includes("Celkem") || params.rowNode.parent.includes("Celkem"))) {
                  return 'spendings-total';
                }
                if (params.colDef.headerName.includes("Skutečnost") && params.id.includes("cashFlow")) {
                  return 'spendings-cashflow';
                }
                if (params.colDef.headerName.includes("Skutečnost")) {
                  return 'spendings';
                }
                if (params.rowNode.groupingKey.includes("Celkem") || params.rowNode.parent.includes("Celkem")) {
                  return 'total';
                }
                if (params.id.includes("cashFlow")) {
                  return 'cashflow';
                }
              }}
              sx={{
                height: "80vh",
                '&.MuiDataGrid-root': {
                  borderRadius: '0px',
                  borderLeft: 'none',
                  borderRight: 'none',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold', overflow: 'visible'
                },
                '& .spendings': {
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                },
                '& .total': {
                  fontWeight: 'bold',
                },
                '& .cashflow': {
                  fontWeight: 'bold',
                  backgroundColor: '#f5f5f5',
                },
                '& .spendings-cashflow': {
                  fontWeight: 'bold',
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                  backgroundColor: '#f5f5f5',
                },
                '& .spendings-total': {
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                  fontWeight: 'bold',
                },
                [`.${gridClasses.cell}.pinned-row`]: {
                  fontWeight: 'bold',
                  backgroundColor: '#f5f5f5',
                },
                [`.${gridClasses.cell}.pinned-row_spendings`]: {
                  fontWeight: 'bold',
                  backgroundColor: '#f5f5f5',
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                },
                [`.${gridClasses.cell}.spendings`]: {
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                },
              }}
            />

        ) : (
          <CircularProgress sx={{position: 'absolute', top: '50%', left: '50%'}}/>
        )
      }
    </Sidebar>
  );
}
