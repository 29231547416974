import React from 'react';
import Sidebar from "../components/Sidebar";
import Typography from "@mui/material/Typography";

export default function Home() {
  return (
    <Sidebar>
      <Typography variant="h5" fontWeight="bold">Home</Typography>
      <Typography variant="h7">NOHO</Typography>
    </Sidebar>

  );
}
