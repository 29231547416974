import React from 'react';
import LoginForm from '../components/LoginForm';
import Grid from '@mui/material/Grid';
import loginImage from "../data/images/loginImage.jpg";
import logo from "../data/images/NOHO-modra_RGB 3.svg";

export default function LoginPage() {
  return (
    <div
      className="LoginPage"
      style={{
        backgroundImage: `url(${loginImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        <Grid item xs={12} style={{maxWidth: '550px'}}>
          <img src={logo} alt="logo" style={{width: '100%'}}/>
        </Grid>
        <Grid item xs={12} style={{width: '350px', paddingBottom: '100px'}}>
          <LoginForm/>
        </Grid>
      </Grid>
    </div>
  );
}
