import {createTheme} from '@mui/material/styles';

// Create a base theme to get default values
const baseTheme = createTheme();

const theme = createTheme({
  typography: {
    fontFamily: 'Inter',
    allVariants: {
      fontSize: '0.75rem',
    },
    h4: {
      fontSize: baseTheme.typography.h4.fontSize,
    },
    h5: {
      fontSize: "1rem",
    },
    h7: {
      fontSize: '0.85rem',
    },
  },
  components: {
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: '0.85rem',
        },
        secondary: {
          fontSize: '0.85rem',
        },
      },
    },
  },
});

export default theme;
